exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-en-js": () => import("./../../../src/pages/about-us.en.js" /* webpackChunkName: "component---src-pages-about-us-en-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-account-delete-customer-en-js": () => import("./../../../src/pages/account-delete-customer.en.js" /* webpackChunkName: "component---src-pages-account-delete-customer-en-js" */),
  "component---src-pages-account-delete-en-js": () => import("./../../../src/pages/account-delete.en.js" /* webpackChunkName: "component---src-pages-account-delete-en-js" */),
  "component---src-pages-contact-en-js": () => import("./../../../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dispute-en-js": () => import("./../../../src/pages/dispute.en.js" /* webpackChunkName: "component---src-pages-dispute-en-js" */),
  "component---src-pages-dispute-js": () => import("./../../../src/pages/dispute.js" /* webpackChunkName: "component---src-pages-dispute-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-en-js": () => import("./../../../src/pages/privacy.en.js" /* webpackChunkName: "component---src-pages-privacy-en-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-and-conditions-en-js": () => import("./../../../src/pages/terms-and-conditions.en.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-en-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-terms-en-js": () => import("./../../../src/pages/terms.en.js" /* webpackChunkName: "component---src-pages-terms-en-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

